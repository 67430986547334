// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBz7G_dHr6OE0WxMR3cXeXhIOtPzPACd0w",
  authDomain: "block-c34bd.firebaseapp.com",
  databaseURL: "https://block-c34bd-default-rtdb.firebaseio.com",
  projectId: "block-c34bd",
  storageBucket: "block-c34bd.appspot.com",
  messagingSenderId: "783945049272",
  appId: "1:783945049272:web:6448059b0892cd97e7404d",
};

const firebaseConfig2 = {
  apiKey: "AIzaSyC8qrbAYUqVfrhcTLIzzSctR9Yti31xZso",
  authDomain: "blockbet-telegram.firebaseapp.com",
  databaseURL: "https://blockbet-telegram-default-rtdb.firebaseio.com",
  projectId: "blockbet-telegram",
  storageBucket: "blockbet-telegram.appspot.com",
  messagingSenderId: "610788993704",
  appId: "1:610788993704:web:b199f214adc64e1c56309d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, "app1");
const app2 = initializeApp(firebaseConfig2, "app2");


export const db = getFirestore(app);
export const db2 = getFirestore(app2);



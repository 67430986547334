import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import React, { useState } from "react";
import { useMarbleContext } from "../../../../context/MarbleContext";
import Loader from "../../../Loader";
import { useAccount, useBalance } from "wagmi";

export default function DepositModal({ isOpen, close, depositType , closeModel }) {

  const {address} = useAccount();
    const result  = useBalance({
      address: address,
    });
   
  const {
    modal,
    setModal,
    modal2,
    setModal2,
    depositTokens,
    tokenBalance,
    withdrawTokens,
    depositLoading,
    withdrawLoading,
  } = useMarbleContext();

  const [amount, setAmount] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const handleDeposit = async () => {
    setLoading(true);
    try {
      await depositTokens(amount, depositType); // Pass the depositType here
      setAmount(0);
    } catch (error) {
      console.error("Deposit failed:", error);
    } finally {
      setLoading(false);
      setAmount(0)
      close()
    }
  };

  return (
    <>
      <Transition appear show={isOpen}>
        <Dialog
          as="div"
          className="relative z-[100] focus:outline-none"
          onClose={close}
        >
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/50">
            <div className="flex h-[90vh] items-center justify-center p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-0 transform-[scale(95%)]"
                className="w-full"
              >
                <DialogPanel className="w-full flex justify-center">
                  <div class="sm:w-[600px] w-full flex flex-col items-center justify-center gap-5 py-[1%] pb-[2%] rounded-lg shadow bg-[#0B0E13]">
                    <div class="flex items-start  px-[4%]  w-full">
                      <h3 class="text-xl font-rajdhani font-semibold text-white">
                        Deposit 
                      </h3>
                      <button
                        type="button"
                        onClick={close}
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="staticModal"
                      >
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close</span>
                      </button>
                    </div>
                    <div className="px-[4%] w-full">
                      <div className="bg-white w-full  h-[45px] rounded-[8px] flex items-center justify-between border-none">
                        <input
                          className=" p-3 outline-none  w-full h-full rounded-[8px] text-black"
                          type="number"
                          placeholder="Enter Amount"
                          onChange={(e) => setAmount(e.target.value)}
                          value={amount}
                        />
                        <button className="bg-[#000000] border border-black h-[45px] px-5 uppercase font-[600] text-[17px] text-[#45F882] font-rajdhani "
                        onClick={()=> setAmount(result?.data?.formatted ?  parseFloat(result?.data?.formatted).toFixed(4) : 0 )}
                        >
                          Max
                        </button>
                        <button
                          onClick={handleDeposit}
                          className="bg-[#45F882] rounded-r-[8px] h-[45px] px-5 uppercase font-[600] text-[17px] text-black font-rajdhani "
                        >
                          {depositLoading ? <Loader /> : "DEPOSIT"}
                        </button>
                      </div>
                      <h6 className="text-center font-rajdhani mt-4 text-white">
                        You will receive : {amount * 1000} ST Credits
                      </h6>

                      <h6 className="text-center font-rajdhani mt-4 text-white">
                        1 ETH = 1000 stake in deposit tokens
                      </h6>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}



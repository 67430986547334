import React, { useEffect, useState } from "react";
import { BsTelegram, BsTwitter, BsTwitch } from "react-icons/bs";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { FaRegBell } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useMarbleContext } from "../../../context/MarbleContext";
import Logo from "../../../assets/logo.png";
import Withdraw from "./popover/Withdraw";
import Deposit from "./popover/Deposit";
import { MdOutlineSettings } from "react-icons/md";
import {
  ConnectButton,
  useAccountModal,
  useConnectModal,
} from "@rainbow-me/rainbowkit";
import DepositModal from "./modals/DepositModal";

import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import Notification from "./modals/NotificationModal";
import RaceComponent from "../../RaceComponent";
import { useAccount } from "wagmi";

const Header = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [transactions, setTransactions] = useState([]);
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();

  const {address} = useAccount();

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  const {
    raceStatus,
    setModal,
    modal,
    setModal2,
    modal2,
    depositLoading,
    withdrawLoading,
    currentRaceid,
    points,
  } = useMarbleContext();

  useEffect(() => {
    const fetchTransactions = () => {
      const storedTransactions =
        JSON.parse(localStorage.getItem("transactions")) || [];
      setTransactions(storedTransactions);
    };

    fetchTransactions();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const remaining = calculateTimeRemaining();
      setTimeRemaining(remaining);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeRemaining() {
    const targetTimeInMilliseconds = new Date(1690408800000).getTime();
    const currentTimeInMilliseconds = new Date().getTime();
    const timeDifference = targetTimeInMilliseconds - currentTimeInMilliseconds;

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { hours, minutes, seconds };
  }
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const [toggle, setToggle] = useState(false);
  const handleClose = () => setModal(false);

  return (
    <div className="fixed top-0 left-0 w-full sm:h-[12vh] h-[10vh] text-white bg-[#000000] z-50 flex gap-2 sm:gap-0 items-center justify-between ">
      <div className=" w-full sm:px-[3%] pr-[5%]  flex justify-between items-center  ">
        <div className=" h-[12vh] flex items-center ">
          <img src={Logo} className="relative w-[70px] z-[10000]" alt="logo" />
        </div>
        <div className="absolute sm:w-[500px] w-[200px] h-full skew-x-[40deg] top-0 -left-[15%] bg-[#0F1C23]"></div>
      {address &&  <RaceComponent currentRaceid={currentRaceid} address={address}/>}

        {/* <div
          className={`font-[600] text-[13px] xl:text-[16px] ${
            raceStatus
              ? "text-green-500 border-green-500"
              : "text-red-500 border-red-500"
          }  border-[2px]  p-2 rounded-[8px]`}
        >
          {raceStatus ? "Bet Started" : "Bet Ended"}
        </div> */}
        {/* {timeRemaining.hours}h{" "}
        {timeRemaining.minutes}m {timeRemaining.seconds}s */}
        <div className="lg:flex hidden uppercase font-rajdhani font-semibold text-lg gap-10 item-center">
          {/* <div>Game Id- {currentRaceid}</div> */}
          <Popover>
            {({ close }) => (
              <>
                <PopoverButton className="outline-none ">
                  <div className="">
                    <div class="d-xxl-block d-none">
                      <span class="th-btnn ">Game Id {currentRaceid} </span>
                    </div>
                  </div>
                </PopoverButton>
                {/* <Transition
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >

              </Transition> */}
              </>
            )}
          </Popover>
          <Withdraw closeMenu={closeMenu} />
          <Deposit closeMenu={closeMenu} />
          {/* <div class="d-xxl-block d-none cursor-pointer">
            <span class="th-btnn  "> White Paper</span>
          </div> */}
          <div className="sm:flex hidden">
            <ConnectButton />
          </div>
          <Notification />
        </div>
        {/* <div className="hidden lg:flex items-center gap-7 text-[18px] xl:text-[23px]">
          <a href="https://t.me/BlockStakeETH">
            <BsTelegram />
          </a>
          <a href="https://twitter.com/blockstake_live">
            <BsTwitter />
          </a>
        </div> */}

        {/*       <button className="hidden lg:block py-1 px-1 xl:px-2 capitalize font-[700] text-[16px] xl:text-[20px] border-[1px] rounded-[8px] border-white">
        Set Username
        </button> */}

        <div className="sm:hidden flex items-center gap-3 cursor-pointer">
          <Notification />
        
          {!menuOpen ? (
            <HiOutlineMenuAlt4
              onClick={() => {
                setMenuOpen(true);
              }}
              className="lg:hidden block text-[30px]"
            />
          ) : (
            <AiOutlineClose
              onClick={() => {
                setMenuOpen(false);
              }}
              className="lg:hidden block text-[20px]  z-50"
            />
          )}
        </div>
        {/* mobile nav ------------------------------------------------- */}
        {menuOpen && (
          <div className="lg:hidden pt-[20%] px-[5%] font-rajdhani flex flex-col items-center  bg-black gap-5  fixed top-0 right-0 w-full h-full ">
            <Withdraw closeMenu={closeMenu} />
            <Deposit closeMenu={closeMenu} />
            {/* <Notification />

            <div className="flex items-center gap-7 text-[18px] xl:text-[23px]">
              <a href="https://t.me/BlockStakeETH">
                <BsTelegram />
              </a>
              <a href="https://twitter.com/blockstake_live">
                <BsTwitter />
              </a>
            </div> */}
            {/*           <button className="py-1 px-1 xl:px-2 capitalize font-[700] text-[16px] xl:text-[20px] border-[1px] rounded-[8px] border-white">
            Set Username
          </button> */}
            {/* <ConnectButton chainStatus={"none"} /> */}
            {/* <div className="w-full mt-[5%]">
              <div class="d-xxl-block d-none w-full">
                <span class="th-btn">Connect Button</span>
              </div>
            </div> */}
            <ConnectButton />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

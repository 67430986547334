import { Button, Dialog, DialogPanel } from "@headlessui/react";
import { useEffect, useState } from "react";
import { FaRegBell } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { MdAccountBalanceWallet } from "react-icons/md";
import { formatDistanceToNow } from "date-fns";
import { useMarbleContext } from "../../../../context/MarbleContext";

export default function Notification() {
  const [isOpen, setIsOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [hasUnread, setHasUnread] = useState(false);

  const { filteredTransactions} = useMarbleContext();

  useEffect(() => {
    const fetchTransactions = () => {
      const storedTransactions =
        JSON.parse(localStorage.getItem("transactions")) || [];
      const sortedTransactions = storedTransactions.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setTransactions(sortedTransactions);
      checkUnread(sortedTransactions);
    };

    fetchTransactions();

    const handleStorageChange = () => {
      fetchTransactions();
    };

    window.addEventListener("storage", handleStorageChange);
    document.addEventListener("transactionChange", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      document.removeEventListener("transactionChange", handleStorageChange);
    };
  }, []);

  function open() {
    setIsOpen(true);
    markAsRead();
  }

  function close() {
    setIsOpen(false);
  }

  function checkUnread(transactions) {
    const unread = transactions.some((transaction) => !transaction.read);
    setHasUnread(unread);
  }

  function markAsRead() {
    const updatedTransactions = transactions.map((transaction) => ({
      ...transaction,
      read: true,
    }));
    setTransactions(updatedTransactions);
    localStorage.setItem("transactions", JSON.stringify(updatedTransactions));
    setHasUnread(false);
  }

 const sortedFilteredTransactions = [...filteredTransactions].sort(
   (a, b) => new Date(b.date) - new Date(a.date)
 );


  return (
    <>
      <Button onClick={open}>
        <div className="relative">
          <div className="w-[40px] h-[40px] rounded-full border border-black flex justify-center items-center">
            <FaRegBell className="relative text-[24px] cursor-pointer" />
            {hasUnread && (
              <div className="absolute top-[5%] w-[7px] h-[7px] right-[19%] bg-[#45F882] rounded-full"></div>
            )}
          </div>
        </div>
      </Button>

      <Dialog
        open={isOpen}
        as="div"
        className="relative z-10 focus:outline-none"
        onClose={close}
        __demoMode
      >
        <div className="fixed inset-0 z-10 w-screen h-screen font-rajdhani">
          <div className="flex h-screen sm:mt-[6%] mt-[20%] items-start justify-end p-4">
            <DialogPanel
              transition
              className=" relative z-[10000] shadow-md w-full max-w-[350px]  max-h-[350px] rounded-xl overflow-y-auto over-black bg-[#000000] p-4  backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 sidebar"
            >
              <div className="flex flex-col gap-3 ">
                <div className=" flex justify-between items-center text-white mb-3">
                  <div className="flex items-center gap-2 ">
                    <FaRegBell className="relative text-[16px] cursor-pointer" />
                    <h5 className="font-clash_regular text-base font-semibold mt-1 capitalize ">
                      Notifications
                    </h5>
                  </div>

                  <IoClose
                    onClick={close}
                    className="text-[18px] cursor-pointer"
                  />
                </div>
                <div>
                  <ul className="text-sm flex flex-col gap-3 font-medium text-white">
                    {sortedFilteredTransactions.length > 0 ? (
                      sortedFilteredTransactions.map((transaction, index) => (
                        <li key={index}>
                          <div className="flex h-[70px] bg-[#252e3f96] rounded-md w-full">
                            <div className="flex justify-center items-center h-full bg-[#252e3f] rounded-l-md w-[20%]">
                              <MdAccountBalanceWallet className="text-[18px] text-[#45F882]" />
                            </div>
                            <div className="w-[80%] p-3">
                              <div className="flex justify-between items-center">
                                <h6 className="font-semibold capitalize">
                                  {transaction.type}
                                </h6>
                                <p className="text-gray-300">
                                  {" "}
                                  {formatDistanceToNow(
                                    new Date(transaction.date),
                                    { addSuffix: true }
                                  )}
                                </p>
                              </div>
                              <h6 className="text-gray-300 mt-1">
                                {transaction.message}
                              </h6>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <p>No notifications available.</p>
                    )}
                  </ul>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}

import React, { useState } from "react";
import { useAccount } from "wagmi";
import { useMarbleContext } from "./context/MarbleContext";

const Admin = () => {
  const owner = "0xaecc4921C28D92DAD6fBaDfEea84909652D3A299";
  const { address } = useAccount();
  const { currentRaceid, endRace } = useMarbleContext();
  const [winner, setWinner] = useState(0);

  return (
    <>
      {address?.toLowerCase() === owner?.toLowerCase() && (
        <>
          <div className="flex h-screen container mx-auto">
            <div className="flex-1 p-8">
              <div className="flex items-center justify-between mb-12">
                <div>
                  <h1 className="text-indigo-dark text-4xl text-white">
                    Race Id ## {currentRaceid}
                  </h1>
                </div>
              </div>

              <div className="flex justify-between items-baseline mb-3">
                <h3 className="text-indigo-dark font-bold text-blue-600">
                  Operations You Can Perform
                </h3>
                <small className="text-grey-dark font-bold text-white">Today</small>
              </div>

              <ul className="list-reset mb-8">
                <input
                  className="border-[1.5px] px-3 border-black w-[55%] h-full text-black"
                  type="number"
                  value={winner}
                  onChange={(e) => setWinner(e.target.value)}
                />
                <li className="flex">
                  <button
                    type="button"
                    className="ml-2 border border-black block py-3 flex-1 text-white"
                    onClick={() => endRace(winner)}
                  >
                    End Race
                  </button>

                  <button
                    type="button"
                    className="ml-2 border border-black block py-3 flex-1 text-white"
                  >
                    Start Betting
                  </button>

                  <button
                    type="button"
                    className="ml-2 border border-black block py-3 flex-1 text-white"
                  >
                    Stop Betting
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
      {address?.toLowerCase() !== owner?.toLowerCase() && (
        <h1 className="text-red-500">You are not an Admin</h1>
      )}
    </>
  );
};

export default Admin;

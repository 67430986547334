import React, { useState } from "react";
import { AiFillTrophy } from "react-icons/ai";
import { RiMedalFill } from "react-icons/ri";
import { useMarbleContext } from "../../../../context/MarbleContext";
import { IoFileTrayFull } from "react-icons/io5";

const PreviousGames = () => {
  const { allRaces } = useMarbleContext();
  const [currentPage, setCurrentPage] = useState(1);
  const betsPerPage = 10;

  console.log(allRaces, "allRaces");

  // Sort allRaces in descending order based on raceId
  const sortedRaces = allRaces.sort((a, b) => b.raceId - a.raceId);

  // Logic for pagination
  const indexOfLastBet = currentPage * betsPerPage;
  const indexOfFirstBet = indexOfLastBet - betsPerPage;
  const currentBets = sortedRaces.slice(indexOfFirstBet, indexOfLastBet);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="sm:text-lg w-full">
      <div className="overflow-x-auto md:overflow-x-hidden bg-[#181D2A] py-3 sm:p-[2%] p-[3%] mt-[3%] rounded-xl flex gap-0 sm:gap-4 flex-col">
        <div className="flex space-x-4">
          <button className="bg-[#45F882] rounded-xl font-semibold text-black py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            GAME ID
          </button>
          <button className="bg-[#45F882] rounded-xl font-semibold text-black py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            TOTAL BET
          </button>
          <button className="bg-[#45F882] rounded-xl font-semibold text-black py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            WINNER
          </button>
          <button className="bg-[#45F882] rounded-xl font-semibold text-black py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            STATUS
          </button>
        </div>
        {currentBets.length !== 0 ? (
          currentBets.map((item, index) => {
            const winnerText =
              item.status && item.options && item.options[item.winner]
                ? item.options[item.winner]
                : "No Winner Declared Yet";
            return (
              <div key={index}>
                <div className="flex space-x-4">
                  <button className="bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    #00{item.raceId}
                  </button>
                  <button className="flex items-center gap-1 bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    <AiFillTrophy />
                    {parseFloat(item.totalAmount).toFixed(3)}
                  </button>
                  <button className="flex items-center gap-1 bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    <RiMedalFill />
                    {winnerText}
                  </button>
                  <button className="bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    {item.status ? "Completed" : "Incompleted"}
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex justify-center items-center w-full flex-col my-[2%]">
            <div className="text-[#45F882] text-[50px]">
              <IoFileTrayFull />
            </div>

            <h4>No data to show </h4>
          </div>
        )}
        {/* Pagination */}
        <div className="flex justify-center mt-4">
          {Array.from({ length: Math.ceil(sortedRaces.length / betsPerPage) }, (_, i) => (
            <button
              key={i}
              className={`${
                currentPage === i + 1
                  ? "bg-[#45F882] text-black"
                  : "bg-[#0B0E13] text-white"
              } font-semibold py-2 px-4 mx-1 rounded-md`}
              onClick={() => paginate(i + 1)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PreviousGames;

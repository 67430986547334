import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import React, { useState } from "react";
import { useMarbleContext } from "../../../../context/MarbleContext";
import Loader from "../../../Loader";


export default function WithdrawalModal({ isOpen, close, withdrawType }) {
  const {
    modal,
    setModal,
    modal2,
    setModal2,
    depositTokens,
    tokenBalance,
    withdrawTokens,
    depositLoading,
    withdrawLoading,
    points
  } = useMarbleContext();

  const [amount2, setAmount2] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const handleWithdraw = async () => {
    setLoading(true);
    try {
      const success = await withdrawTokens(+amount2, withdrawType);
      if (success) {
        setAmount2(0);
      }
    } catch (error) {
      console.error("Withdraw failed:", error);
    } finally {
      setLoading(false);
      setAmount2(0);
      close();
    }
  };


  return (
    <>
      <Transition
        appear
        show={isOpen}
        className="relative z-[100] focus:outline-none"
      >
        <Dialog as="div" onClose={close}>
          <div className="fixed inset-0  w-screen overflow-y-auto bg-black/50">
            <div className="flex h-[90vh] items-center justify-center">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-0 transform-[scale(95%)]"
                className="w-full"
              >
                <DialogPanel className="w-full flex justify-center">
                  <div class="sm:w-[600px] w-full flex flex-col gap-5 items-center justify-center pb-[2%] py-[1%] rounded-lg shadow bg-[#0B0E13]">
                    <div class="flex px-[4%] w-full ">
                      <h3 class="text-xl font-rajdhani font-semibold text-white">
                        Withdraw
                      </h3>
                      <button
                        type="button"
                        onClick={close}
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="staticModal"
                      >
                        <svg
                          class="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span class="sr-only">Close</span>
                      </button>
                    </div>
                    <div className="px-[4%] mb-4 w-full">
                      <div className="bg-white w-full  h-[45px] rounded-[8px] flex items-center justify-between border-none">
                        <input
                          className=" p-3 outline-none bg-transparent  w-full h-full rounded-[8px] text-black"
                          type="number"
                          placeholder="Enter Amount"
                          onChange={(e) => setAmount2(e.target.value)}
                          value={amount2}
                        />{" "}
                        <button className="bg-[#000000] border border-black h-[45px] px-5 uppercase font-[600] text-[17px] text-[#45F882] font-rajdhani " 
                        onClick={()=> setAmount2(points)}
                        >
                          Max
                        </button>
                        <button
                          onClick={handleWithdraw}
                          className="bg-[#45F882] rounded-r-[8px] h-[45px] px-5 uppercase font-[600] text-[17px] text-black font-rajdhani "
                        >
                          {withdrawLoading ? <Loader /> : "Withdraw"}
                        </button>
                      </div>
                      <h6 className="text-center font-rajdhani mt-4 text-white">
                        You will receive : {amount2 / 1000} ETH
                      </h6>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { IoCopy } from "react-icons/io5";
import { TiTick } from "react-icons/ti";
import { blockBetContractABI } from "./utils/constant";
import { blockBetContractAddress } from "./utils/constant";
import { blockBetTokenContractABI } from "./utils/constant";
import { blockBetTokenContractAddress } from "./utils/constant";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEthersSigner } from "./web3-services/signer.ts";
import { useNetwork } from "wagmi";
import { ethers } from "ethers";
import { toast } from "react-toastify";

const Deposit = () => {
  const [isCopied, setIsCopied] = React.useState(false);

  const [securityCode2, setSecurityCode2] = useState("");
  const [amount, setAmount] = useState("");
  const [txnHashDeposit, setTxnHashDeposit] = useState("");
  const { chain } = useNetwork();

  const [activeChain, setActiveChainId] = useState(chain?.id);

  const signer = useEthersSigner(activeChain);

  useEffect(() => {
    setActiveChainId(chain?.id);
  }, [chain?.id]);

  const getContractInstance = async (contractAddress, contractAbi) => {
    try {
      let contractInstance = new ethers.Contract(
        contractAddress,
        contractAbi,
        signer
      );
      return contractInstance;
    } catch (error) {
      console.log("Error in deploying contract", error);
    }
  };

  function truncateTxHash(hash) {
    if (hash.length <= 12) {
      // Return full hash if it's short
      return hash;
    }

    const start = hash.substring(0, 6);
    const end = hash.substring(hash.length - 6);

    return `${start}...${end}`;
  }

  const handleDeposit = async () => {
    if (!securityCode2 || !amount) {
      alert("Please enter a security code and amount.");
      return;
    }

    let blockBetInstance = await getContractInstance(
      blockBetContractAddress,
      blockBetContractABI
    );

    let blockBetTokenInstance = await getContractInstance(
      blockBetTokenContractAddress,
      blockBetTokenContractABI
    );

    try {
      let _amount = ethers.utils.parseEther(amount);
      let txApprove = await blockBetTokenInstance.approve(
        blockBetContractAddress,
        _amount
      );
      await txApprove.wait(1);
      const txDeposit = await blockBetInstance.deposit(securityCode2, _amount);
      const txResponse = await txDeposit.wait();
      setTxnHashDeposit(txResponse.transactionHash);
      toast.success("Deposit successful!");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Deposit failed!");
    }
  };

  const handleCopy = (copyText) => {
    setIsCopied(true);
    navigator.clipboard.writeText(copyText);
  };

  return (
    <div className="bg-[#1D1D1B] w-full h-screen overflow-y-scroll items-center  md:pt-0 flex flex-col">
      <h1 className="text-white font-[700] text-[22px] sm:text-[24px] text-center  my-5 2xl:mt-[3%]">
        BlockBet Telegram Bot Deposit
      </h1>
      <ConnectButton />
      <div className="border border-[#B6B6B6] mt-5 flex flex-col mb-6 rounded-[5px] justify-center w-[95%] sm:w-[450px] py-12 px-10">
        <div className="flex items-center justify-between gap-4">
          <input
            className="text-white bg-[#5A5A5A] text-[15px] px-2 py-3 w-full rounded-[5px] "
            type="number"
            placeholder="Security Code 2"
            value={securityCode2}
            onChange={(e) => setSecurityCode2(e.target.value)}
          />
          <input
            className="text-white bg-[#5A5A5A] text-[15px] px-2 py-3 w-full rounded-[5px] "
            type="number"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <h3 className="text-white font-[600] mt-4 mb-2">Transaction Hash</h3>
        <div className="text-white bg-[#5A5A5A] text-[15px] px-3 py-3 w-full flex items-center justify-between  rounded-[5px] ">
          <p>{truncateTxHash(txnHashDeposit)}</p>
          {
            <IoCopy
              onClick={() => {
                handleCopy(txnHashDeposit);
              }}
              className="cursor-pointer"
            />
          }
        </div>
        <button
          className="text-white bg-[#812A88] hover:bg-[#812A8899] font-medium text-[15px] mt-10 py-3 px-6 w-full rounded-[5px] "
          onClick={handleDeposit}
        >
          Deposit
        </button>
      </div>
    </div>
  );
};

export default Deposit;

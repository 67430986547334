import React, { useEffect, useRef, useState } from "react";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useMarbleContext } from "../../../context/MarbleContext";

const Sidebar = () => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const { formatTimestamp, trimAndAddAddress } = useMarbleContext();
  const { address } = useAccount();
  const scroll = useRef();

  const sendMessage = async () => {
    try {
      if (message.trim() === "") {
        toast.error("Enter valid message");
        return;
      }

      if (!address) {
        toast.error("Wallet not connected");
        return;
      }

      await addDoc(collection(db, "messages"), {
        text: message,
        address: address,
        createdAt: serverTimestamp(),
      });

      setMessage(""); // Clear message input after sending
      scroll.current.scrollIntoView({ behavior: "smooth" }); // Scroll to bottom
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const q = query(
      collection(db, "messages"),
      orderBy("createdAt", "desc"),
      limit(15)
    );

    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const fetchedMessages = [];
      QuerySnapshot.forEach((doc) => {
        fetchedMessages.push({ ...doc.data(), id: doc.id });
      });

      setMessages(fetchedMessages.reverse()); // Reverse to maintain order
      scroll.current.scrollIntoView({ behavior: "smooth" }); // Scroll to bottom
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="flex w-full sm:h-screen h-full sm:pb-0 pb-[7%] bg-gradient-to-b md:pt-[17vh] xl:pt-[18vh] from-[#282828] flex-col items-center relative pt-[6%] font-poppins">
      <div className="flex flex-col items-center gap-4 h-[80vh] px-3 overflow-y-scroll w-full mb-[3%] over">
        {/* card -------------------------------------------------------------- */}
        {messages.map((message, index) => (
          <div
            key={index}
            className="p-2 w-full bg-gradient-to-b from-[#282828] border border-[#45F882] rounded-lg"
          >
            <div className="flex items-center justify-between text-[12px]">
              <p>
                <a className="text-red-400 text-base">
                  {trimAndAddAddress(message.address)}
                </a>
              </p>
              {message.createdAt && (
                <p className="text-green-400">
                  {formatTimestamp(message.createdAt.seconds)}
                </p>
              )}
            </div>
            <p className="text-[14px] text-white">{message.text}</p>
          </div>
        ))}
        <span ref={scroll}></span>
        {/* card --------------------------------------------------------------- */}
      </div>
      {/* bottom ------------------------------------------------------------- */}
      <div className="w-full bg-[#0B0E13] bg-gradient-to-r from-[#0B0E13] from-50% to-[#0B0E13] to-50% flex flex-col border-t-[4px] border-t-[#45F882] mt-[3%]">
        <div onSubmit={(event) => sendMessage(event)}>
          <div className="px-3 flex flex-col gap-3 mt-3">
            <input
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              id="messageInput"
              name="messageInput"
              type="text"
              placeholder="Your message here"
              className="w-full h-[45px] px-3 rounded-lg bg-transparent outline-none text-gray-400 placeholder:text-[18px] font-rajdhani border border-[#45F882]"
            />
            <div className="">
              <div
                className="d-xxl-block d-none cursor-pointer"
                onClick={sendMessage}
              >
                <span className="th-btnn">Send</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import { query, collection, getDocs } from "firebase/firestore";
import { db } from "../../../../config/firebase";
import { useMarbleContext } from "../../../../context/MarbleContext";
import { IoFileTrayFull } from "react-icons/io5";

const Recent = () => {
  const { formatTimestamp, trimAndAddAddress, allBets } = useMarbleContext();
  const [gameOptions, setGameOptions] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const betsPerPage = 10;

  useEffect(() => {
    const fetchGameOptions = async () => {
      const gameOptionsTemp = {};
      const querySnapshot = await getDocs(collection(db, "games"));
      querySnapshot.forEach((doc) => {
        const gameId = doc.id;
        const options = doc.data().Options;
        gameOptionsTemp[gameId] = options;
      });
      setGameOptions(gameOptionsTemp);
    };

    fetchGameOptions();
  }, []);

  // Calculate the index of the first and last bets to be displayed
  const indexOfLastBet = currentPage * betsPerPage;
  const indexOfFirstBet = indexOfLastBet - betsPerPage;
  const currentBets = allBets.slice(indexOfFirstBet, indexOfLastBet);

  // Calculate the total number of pages
  const totalPages = Math.ceil(allBets.length / betsPerPage);

  // Function to change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const sortedBets = allBets.sort((a, b) => b.raceId - a.raceId);
  return (
    <div className="sm:text-lg w-full">
      <div className="overflow-x-auto md:overflow-x-hidden bg-[#181D2A] sm:p-[2%] p-[3%] mt-[3%] rounded-xl flex gap-0 sm:gap-4 flex-col">
        <div className="flex space-x-4">
          <button className="bg-[#45F882] rounded-xl font-semibold text-black py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            RACE ID
          </button>
          <button className="bg-[#45F882] rounded-xl font-semibold text-black py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            USER
          </button>
          <button className="bg-[#45F882] rounded-xl font-semibold text-black py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            BET
          </button>
          <button className="bg-[#45F882] rounded-xl font-semibold text-black py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            AMOUNT
          </button>
          <button className="bg-[#45F882] rounded-xl font-semibold text-black py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            TIME
          </button>
        </div>
        {sortedBets.length !== 0 ? (
          currentBets.map((item, index) => {
            const gameOptionsList = gameOptions[item.raceId];
            const playerText = gameOptionsList ? gameOptionsList[item.playerId] : "";
            return (
              <div key={index}>
                <div className="flex space-x-4">
                  <button className="bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    #00{item.raceId}
                  </button>
                  <button className="bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    {trimAndAddAddress(item?.wallet_address)}
                  </button>
                  <button className="bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    {playerText}
                  </button>
                  <button className="bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    {item.amount}
                  </button>
                  <button className="bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    {formatTimestamp(item?.timestamp)}
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex justify-center items-center w-full flex-col my-[2%]">
            <div className="text-[#45F882] text-[50px]">
              <IoFileTrayFull />
            </div>

            <h4>No data to show</h4>
          </div>
        )}

        {/* Pagination Controls */}
        {allBets.length > betsPerPage && (
          <div className="flex justify-center space-x-2 mt-4">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`py-2 px-4 rounded-xl ${
                  currentPage === index + 1 ? 'bg-[#45F882] text-black' : 'bg-[#0B0E13] text-white'
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Recent;

import { ConnectButton } from "@rainbow-me/rainbowkit";
import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { blockBetContractABI } from "./utils/constant";
import { blockBetContractAddress } from "./utils/constant";
import { blockBetTokenContractABI } from "./utils/constant";
import { blockBetTokenContractAddress } from "./utils/constant";
import { useNetwork } from "wagmi";
import { useEthersSigner } from "./web3-services/signer.ts";

const BlockBetBot = () => {
  const [securityCode1, setSecurityCode1] = useState("");
  const [securityCode2, setSecurityCode2] = useState("");
  const [txnHashConnect, setTxnHashConnect] = useState("");
  const [txnHashDeposit, setTxnHashDeposit] = useState("");
  const [amount, setAmount] = useState("");
  const { chain } = useNetwork();
  const [activeChain, setActiveChainId] = useState(chain?.id);

  useEffect(() => {
    setActiveChainId(chain?.id);
  }, [chain?.id]);

  const signer = useEthersSigner(activeChain);

  const getContractInstance = async (contractAddress, contractAbi) => {
    try {
      let contractInstance = new ethers.Contract(
        contractAddress,
        contractAbi,
        signer
      );
      return contractInstance;
    } catch (error) {
      console.log("Error in deploying contract", error);
    }
  };

  const handleConnectAndApprove = async () => {
    if (!securityCode1) {
      alert("Please enter a security code.");
      return;
    }

    let blockBetInstance = await getContractInstance(
      blockBetContractAddress,
      blockBetContractABI
    );

    console.log(signer);

    try {
      const tx = await blockBetInstance.connectAndApprove(securityCode1);
      const txResponse = await tx.wait();
      setTxnHashConnect(txResponse.transactionHash);
      console.log(txResponse.transactionHash);
      alert("Connect and Approve successful!");
    } catch (error) {
      console.error("Error:", error);
      alert("Connect and Approve failed.");
    }
  };

  const handleDeposit = async () => {
    if (!securityCode2 || !amount) {
      alert("Please enter a security code and amount.");
      return;
    }

    let blockBetInstance = await getContractInstance(
      blockBetContractAddress,
      blockBetContractABI
    );

    let blockBetTokenInstance = await getContractInstance(
      blockBetTokenContractAddress,
      blockBetTokenContractABI
    );

    try {
      let _amount = ethers.utils.parseEther(amount);
      let txApprove = await blockBetTokenInstance.approve(
        blockBetContractAddress,
        _amount
      );
      await txApprove.wait(1);
      const txDeposit = await blockBetInstance.deposit(securityCode2, _amount);
      const txResponse = await txDeposit.wait();
      setTxnHashDeposit(txResponse.transactionHash);
      alert("Deposit successful!");
    } catch (error) {
      console.error("Error:", error);
      alert("Deposit failed.");
    }
  };

  return (
    <div className="w-screen h-screen p-10 flex flex-col justify-start items-center gap-10">
      <ConnectButton />
      <div className="w-full flex flex-col justify-between items-center">
        <div className="w-[35%] flex flex-row justify-between items-center">
          <input
            type="number"
            placeholder="Security Code"
            value={securityCode1}
            onChange={(e) => setSecurityCode1(e.target.value)}
            className="p-2 w-[50%] border-solid border-black border-2"
          />
          <button
            className="bg-blue-400 h-[3rem] w-[12rem] rounded-lg"
            onClick={handleConnectAndApprove}
          >
            Connect and Approve
          </button>
        </div>
        <span>Transaction Hash: {txnHashConnect}</span>
      </div>

      <div className="w-full flex flex-col justify-between items-center">
        <div className="w-[45%] flex flex-row justify-between items-center">
          <input
            type="number"
            placeholder="Security Code 2"
            value={securityCode2}
            onChange={(e) => setSecurityCode2(e.target.value)}
            className="p-2 w-[30%] border-solid border-black border-2"
          />
          <input
            type="number"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="p-2 w-[30%] border-solid border-black border-2"
          />
          <button
            className="bg-blue-400 h-[3rem] w-[12rem] rounded-lg"
            onClick={handleDeposit}
          >
            Deposit
          </button>
        </div>
        <span>Transaction Hash: {txnHashDeposit}</span>
      </div>
    </div>
  );
};

export default BlockBetBot;

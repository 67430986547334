import React, { useState } from "react";
import Header from "./components/Header";
import Center from "./components/Center";
import Sidebar from "./components/Sidebar";
import { useMarbleContext } from "../../context/MarbleContext";
import bgg from "../../assets/bg.png";
import { IoIosChatboxes } from "react-icons/io";
import Loader from "../Loader";
import DepositModal from "./components/modals/DepositModal";

const style = {
  bg: {
    backgroundImage: ` url(${bgg})`,

    backgroundPosition: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",

    padding: "0px 5%",
    position: "relative",
  },
};
const Dashboard = () => {

  const [toggle, setToggle] = useState(false);

  return (
    <div className=" relative sm:h-screen h-full overflow-y-hidden w-full">
      <Header />
      <div className=" ">
        <div className="bg-[#0B0E13] relative text-white w-full h-full z-0 overflow-hidden sm:pl-[3%] sm:px-0 px-[5%]">
          <div className="w-full h-full  grid lg:grid-cols-12 grid-cols-1  ">
   
            <div className="col-span-9">
              <Center />
            </div>
            <div className="col-span-3">
              <div className="sm:flex hidden">
                <Sidebar />
              </div>
            </div>
          </div>
        </div>
        ;
      </div>
      <div className="sm:hidden fixed bottom-[4%] right-[4%] w-[60px] h-[60px] bg-black rounded-[16px] flex justify-center items-center">
        <div
          onClick={() => setToggle(!toggle)}
          className=" text-[40px] text-[#45F882]"
        >
          <IoIosChatboxes />
        </div>
      </div>
      <div
        className={`${
          toggle ? "flex flex-col" : "hidden"
        } px-[5%] py-3 bg-[#000000] z-[100] fixed top-0  right-0 border border-black w-full h-full rounded-xl sidebar `}
      >
        <div
          onClick={() => setToggle(!toggle)}
          className="text-white font-rajdhani flex justify-end "
        >
          close
        </div>
        <Sidebar />
      </div>
    </div>
  );
};

export default Dashboard;

import React from "react";
import "./App.css";
import "./style.css";
import Dashboard from "./components/Dashboard/Dashboard";
import { Routes, Route } from "react-router-dom";
import Admin from "./Admin";
import AdminPanel from "./AdminPanel.js";
import BlockBetBot from "./BlockBetBot";
import Connect from "./Connect.jsx";
import Deposit from "./Deposit";
import { useMarbleContext } from "./context/MarbleContext.jsx";
import Loader from "./components/Loader.jsx";
import { Toaster } from "sonner";


const App = () => {
  const { raceStatus, setModal, modal, setModal2, modal2 , depositLoading , withdrawLoading } = useMarbleContext();
  return (
    <>
      <Toaster
        position="top-right"
      
      />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/adminPanel" element={<AdminPanel />} />
        <Route path="/blockstakebot" element={<BlockBetBot />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/deposit" element={<Deposit />} />
      </Routes>
    </>
  );
};

export default App;

import React, { useState, useRef } from "react";
import Recent from "./tables/Recent";
import MyBet from "./tables/MyBet";
import Leaderboard from "./tables/Leaderboard";
import PreviousGames from "./tables/PreviousRaces";
import MarblesLeaderboard from "./tables/MarbleLeaderboard";
import { useMarbleContext } from "../../../context/MarbleContext";

import { TwitchPlayer } from "react-twitch-embed";
import { FaUsers } from "react-icons/fa6";
import { MdAccountBalanceWallet } from "react-icons/md";
import { FaSackDollar } from "react-icons/fa6";
import numeral from "numeral";
import { Toaster, toast } from "sonner";
import { useAccount, useBalance } from "wagmi";

const Tables = [
  {
    name: "MY BETS",
    table: <MyBet />,
  },
  {
    name: "CURRENT BETS",
    table: <Recent />,
  },
  {
    name: "PREVIOUS GAMES",
    table: <PreviousGames />,
  },
  {
    name: "USER LEADERBOARD",
    table: <Leaderboard />,
  },
  {
    name: "PLAYER LEADERBOARD",
    table: <MarblesLeaderboard />,
  },
];

const Center = () => {
  const [betOn, setBetOn] = useState(0);
  const [activeTable, setActiveTable] = useState(0);
  const [activePlayerId, setAcivePlayerId] = useState(0);
  const [activeAmoount, setActiveAmount] = useState(0);
  const { address } = useAccount();
  const result = useBalance({
    address: address,
  });
  const {
    totalWager,
    tokenBalance,
    placeYourBet,
    raceStatus,
    symbol,
    isElegible,
    betOnButtons,
    points,
    allRaces,
    currentRaceid,
  } = useMarbleContext();

  const placingBetting = async () => {
    console.log(activePlayerId, activeAmoount);
    if (!activeAmoount && !activePlayerId) {
      toast.error("Insufficient Balance", {
        style: { background: "red" },
        className: "mt-[10vh] border-black ",
      });
      return false;
    }
    console.log(activePlayerId, activeAmoount);
    await placeYourBet(activePlayerId, activeAmoount);
    setActiveAmount("");
  };

  return (
    <div className="w-full sm:h-screen h-full md:pt-[18vh] xl:pt-[22vh] pt-[14vh] lg:pr-6 overflow-y-scroll over">
      {/* top ---------------------------------------------------- */}
      <div className="grid lg:grid-cols-3 gap-5 w-full">
        {/* <div className="font-rajdhani col-span-1 h-[75px] bg-black border border-[#45F882] pt-2.5 px-5 rounded-[10px] flex justify-between flex-col shadow-[4_4px_4px_rgba(0,0,0,0.25)]">
          <div className="flex items-center gap-[10px]">
            <div className="w-[28px] h-[28px] bg-[#45F882] flex justify-center items-center text-white rounded-[6px]">
              <FaUsers className="w-[18px]" />
            </div>
            <h4 className="text-sm font-medium font-poppins ">
              Total Wagered{" "}
            </h4>
          </div>
          <div className="flex pb-7 justify-end">
            <h4 className="text-[21px] font-semibold">
              {numeral(totalWager).format("0,0.000")}{" "}
              <span className="text-[#45F882] leading-[1px]">{" $STAKE "}</span>
            </h4>
          </div>
        </div> */}
        <div className="font-rajdhani col-span-1 h-[75px] bg-[#45F882] rounded-[10px] flex justify-between flex-col pt-2.5 px-5 shadow-[4_4px_4px_rgba(0,0,0,0.25)]">
          <div className="flex items-center gap-[10px]">
            <div className="w-[28px] h-[28px] bg-[#000000] flex justify-center items-center text-white rounded-[6px]">
              <FaSackDollar className="w-[18px]" />
            </div>
            <h4 className="text-sm font-medium font-poppins text-black">
              Stake Credits
            </h4>
          </div>
          <div className="flex pb-7 justify-end">
            <h4 className="text-[21px] font-semibold">
              {numeral(points).format("0,0.000")}
              <span className="text-black">{" ST Credits"}</span>
            </h4>
          </div>
        </div>
        <div className="font-rajdhani col-span-1 h-[75px] bg-black border border-[#45F882] rounded-[10px] flex justify-between flex-col pt-2.5 px-5 shadow-[4_4px_4px_rgba(0,0,0,0.25)]">
          <div className="flex items-center gap-[10px]">
            <div className="w-[28px] h-[28px] bg-[#45F882] flex justify-center items-center text-white rounded-[6px]">
              <MdAccountBalanceWallet className="w-[18px]" />
            </div>
            <h4 className="text-sm font-poppins font-medium ">Balance</h4>
          </div>
          <div className="flex pb-7 justify-end">
            <h4 className="text-[21px] font-semibold">
              {result?.data?.formatted
                ? parseFloat(result?.data?.formatted).toFixed(4)
                : 0}
              <span className="text-green-500">{" ETH "}</span>
            </h4>
          </div>
        </div>
      </div>
      {/* mid ---------------------------------------------------- */}
      <div className="flex flex-col lg:flex-row gap-3 w-full justify-between items-center mt-[3%] ">
        <TwitchPlayer
          channel="blockstake"
          autoplay
          muted
          width="100%"
          height="60vh"
        />
        <div className="flex flex-col gap-1.5 items-start w-full lg:w-max sm:min-w-[210px] px-1">
          {betOnButtons.map((button, index) => {
            return (
              <div className="w-full">
                {betOn === index ? (
                  <button
                    key={index}
                    onClick={() => {
                      setBetOn(index);
                      setAcivePlayerId(+button.key);
                    }}
                    className="w-full py-5 px-4 capitalize bg-[#45F882] rounded-md font-[600] sm:text-[16px] text-sm text-black font-rajdhani"
                  >
                    Bet on {button.text}
                    <br /> Current odds (
                    {allRaces[+currentRaceid]
                      ? allRaces[+currentRaceid].betArray[index] == 0
                        ? 0
                        : (
                            totalWager /
                            allRaces[+currentRaceid].betArray[index]
                          ).toFixed(3)
                      : 0}
                    )
                  </button>
                ) : (
                  <button
                    key={index}
                    onClick={() => {
                      setBetOn(index);
                      setAcivePlayerId(+button.key);
                    }}
                    className="w-full py-5 px-4 capitalize bg-[#ffffff] text-black rounded-md font-[600] sm:text-[16px] text-sm font-rajdhani"
                  >
                    Bet on {button.text}
                    <br /> Current odds (
                    {allRaces[+currentRaceid]
                      ? allRaces[+currentRaceid].betArray[index] == 0
                        ? 0
                        : (
                            totalWager /
                            allRaces[+currentRaceid].betArray[index]
                          ).toFixed(3)
                      : 0}
                    )
                  </button>
                )}
              </div>
            );
          })}
          <a href="https://blockstake.gitbook.io/blockstake/introduction/understanding-betting-odds" target="_blank">
          <div className="flex justify-center items-center p-2 underline">
          <div className="text-white text-sm text-center">See how odds are calculated</div>
          </div>
          </a>
          <div className="flex flex-col justify-center sm:px-0 px-[10%]">
            <div className="flex mt-2 gap-3 items-center bg-[#181D2A] rounded-md px-5 py-3">
              <input
                className="w-full h-[25px] px-1 bg-transparent outline-none text-[#45F882] font-rajdhani border-[1px] border-[#45F882]"
                type="number"
                max={points}
                value={activeAmoount}
                onChange={(e) => setActiveAmount(e.target.value)}
              />
              <button
                className="py-1 px-4 uppercase text-[#45F882] text-[22px] rounded-md bg-[#0E0C17] font-rajdhani font-semibold text-lg"
                onClick={() => setActiveAmount(points)}
              >
                Max
              </button>
            </div>

            {console.log(raceStatus, "raceStatus")}
            {!raceStatus ? (
              <div className="sm:w-full  mt-[5%]">
                <div className="d-xxl-block d-none w-full">
                  <span className="th-btn w-full">Betting Closed</span>
                </div>
              </div>
            ) : isElegible ? (
              <div
                className="sm:w-full px-[4%] mt-[5%]"
                onClick={placingBetting}
              >
                <div className="d-xxl-block d-none w-full">
                  <span className="th-btn w-full"> Place Your bet</span>
                </div>
              </div>
            ) : (
              <div
                className="sm:w-full px-[4%] mt-[5%]"
                onClick={() =>
                  toast.error("Bet Completed or Not Eligible", {
                    style: { background: "black" },
                    className: "mt-[10vh] border-red-500 text-white border",
                  })
                }
              >
                <div className="d-xxl-block d-none w-full">
                  <span className="th-btn w-full"> Betting not allowed</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* bottom tables------------------------------------------- */}
      <div className="font-rajdhani mt-[4%] w-full">
        <div className="flex justify-between sm:py-0 py-5 space-x-4 md:space-x-0 overflow-x-auto md:overflow-x-hidden">
          {Tables.map((item, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  setActiveTable(index);
                }}
                className={`th-btnnn ${
                  activeTable === index ? "active-tab" : ""
                }`}
              >
                <p>{item.name}</p>
              </button>
            );
          })}
        </div>
        {Tables.map((item, index) => {
          return <>{activeTable === index ? <>{item.table}</> : <></>}</>;
        })}
      </div>
      {/* 
      <div className="flex text-[11px] md:text-[15px] items-center justify-center my-[4%] gap-2 text-[#ffffff88]">
        <a href="#">A 1% tax is applied to all losses</a>
      </div> */}
    </div>
  );
};

export default Center;

import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { useMarbleContext } from "../../../../context/MarbleContext";
import { useState } from "react";
import WithdrawalModal from "../modals/WithdrawalModal";

export default function Withdraw({ closeMenu }) {
  const { setModal2, modal2 } = useMarbleContext();
  const [isOpen, setIsOpen] = useState(false);
  const [withdrawType, setWithdrawType] = useState("");

  function open(withdrawType) {
    setWithdrawType(withdrawType);
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
    setWithdrawType("");
  }

  return (
    <div className="">
      <div>
        <Popover>
          {({ close }) => (
            <>
              <PopoverButton className="outline-none ">
                <div className="">
                  <div class="d-xxl-block d-none cursor-pointer">
                    <span class="th-btnn "> Withdraw </span>
                  </div>
                </div>
              </PopoverButton>
              <Transition
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel
                  anchor="bottom"
                  className="sm:w-[300px] w-full z-[100] font-rajdhani divide-y divide-white/5 rounded-b-xl bg-black mt-[3vh]  text-sm/6 [--anchor-gap:var(--spacing-5)]"
                >
                  <div className="p-3 cursor-pointer">
                    <a
                      className="block rounded-lg py-2 px-3 transition hover:bg-white/5"
                      onClick={() => open("eth")}
                    >
                      <p className="font-semibold text-white text-lg">ETH</p>
                      <p className="text-white/50">Withdraw ETH</p>
                    </a>
                  </div>

                  <div className="p-3 cursor-pointer">
                    <a
                      className="block rounded-lg py-2 px-3 transition hover:bg-white/5"
                      onClick={close}
                    >
                      <p className="font-semibold text-white text-lg">STAKE</p>

                      <div className="flex justify-between items-center">
                        <p className="text-white/50">STAKE Withdraw </p>
                        <p className="text-[#45F882]">Coming Soon</p>
                      </div>
                    </a>
                  </div>
                </PopoverPanel>
              </Transition>
            </>
          )}
        </Popover>
        <WithdrawalModal isOpen={isOpen} close={close} withdrawType={withdrawType} />
      </div>
    </div>
  );
}

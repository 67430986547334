import React from "react";
import { AiFillTrophy } from "react-icons/ai";
import { IoFileTrayFull } from "react-icons/io5";
import { RiMedalFill } from "react-icons/ri";
const MarblesLeaderboardData = [
  {
    user: "#16",
    color: "text-yellow-400",
    icon: <AiFillTrophy />,
    marble: "9",
    first: "9",
    second: "9",
    third: "9",
    lastWin: "7/24/2023, 5:17:25 AM",
  },
  {
    user: "#16",
    color: "text-white",
    icon: <AiFillTrophy />,
    marble: "9",
    first: "9",
    second: "9",
    third: "9",
    lastWin: "7/24/2023, 5:17:25 AM",
  },
  {
    user: "#16",
    color: "text-red-400",
    icon: <AiFillTrophy />,
    marble: "9",
    first: "9",
    second: "9",
    third: "9",
    lastWin: "7/24/2023, 5:17:25 AM",
  },
];
const MarblesLeaderboard = () => {
  return (
    <div className="  sm:text-lg w-full  ">
      {/* <div class=" overflow-x-auto  md:overflow-x-hidden bg-[#181D2A] sm:p-[2%] p-[3%] mt-[3%] rounded-xl flex gap-0 sm:gap-4 flex-col">
        <div className="flex   space-x-4 ">
          <button class="bg-[#45F882] rounded-xl font-semibold text-black  py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            PLAYER
          </button>
          <button class=" bg-[#45F882] rounded-xl font-semibold text-black  py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            FiRST
          </button>
          <button class=" bg-[#45F882] rounded-xl font-semibold text-black  py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            SECOND
          </button>
          <button class=" bg-[#45F882] rounded-xl font-semibold text-black  py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            THIRD
          </button>
          <button class=" bg-[#45F882] rounded-xl font-semibold text-black  py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            LAST WIN
          </button>
        </div>

        {false ? (
          MarblesLeaderboardData.map((item, index) => {
            return (
              <div key={index} className={`${item.color} `}>
                <div className="flex   space-x-4 ">
                  <button class=" bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    {item.icon}
                  </button>
                  <button class=" bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    {item.user}
                  </button>
                  <button class=" bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    {item.marble}
                  </button>
                  <button class="flex items-center gap-1 bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    <AiFillTrophy />
                    {item.first}
                  </button>
                  <button class="flex items-center gap-1 bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    <RiMedalFill />
                    {item.second}
                  </button>
                  <button class=" bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    {item.third}
                  </button>
                  <button class=" bg-[#0B0E13] rounded-xl text-white py-2 px-4 mb-4 min-w-[150px] md:mb-0">
                    {item.lastWin}
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex justify-center items-center w-full flex-col my-[2%]">
            <div className="text-[#45F882] text-[50px]">
              <IoFileTrayFull />
            </div>

            <h4>No data to show </h4>
          </div>
        )}
      </div> */}
      <h6 className="text-center text-[#45F882] mt-[3%] font-medium">
        COMING SOON{" "}
      </h6>
    </div>
  );
};

export default MarblesLeaderboard;

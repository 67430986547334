import React, { useState, useEffect } from "react";
import { db2 } from "./config/firebase";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
  addDoc,
  setDoc,
  doc,
  serverTimestamp,
  getCountFromServer,
  updateDoc,
} from "firebase/firestore";
import axios from "axios";
import { toast } from "react-toastify";
import { useMarbleContext } from "./context/MarbleContext";
import { useAccount } from "wagmi";

const AdminPanel = () => {
  const owner2 = "0xaecc4921C28D92DAD6fBaDfEea84909652D3A299";
  const { address } = useAccount();
  const [gamesLength, setGamesLength] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    maxOptions: 0,
  });

  const [formData2, setFormData2] = useState({
    gameId: 0,
    winner: 0,
  });

  const [options, setOptions] = useState(
    Array.from({ length: formData.maxOptions }, () => "")
  );
  const [betAmounts, setBetAmounts] = useState(
    Array.from({ length: formData.maxOptions }, () => 0)
  );

  useEffect(() => {
    const getGamesLength = async () => {
      const collectionRef = collection(db2, "games");
      const snapshot = await getCountFromServer(collectionRef);
      const length = snapshot.data().count;
      setGamesLength(length);
    };
    if (db2) {
      getGamesLength();
    }
  }, [db2]);

  const handleMaxOptionsChange = (e) => {
    const newMaxOptions = parseInt(e.target.value);
    setFormData({ ...formData, maxOptions: newMaxOptions });
    setOptions(Array.from({ length: newMaxOptions }, () => ""));
    setBetAmounts(Array.from({ length: newMaxOptions }, () => 0));
  };

  const handleCreateGame = async () => {
    try {
      console.log(formData);
      const data = {
        name: formData.name,
        description: formData.description,
        maxOptions: formData.maxOptions,
        options: options,
        betAmounts: betAmounts,
        gameId: gamesLength,
        totalAmount: 0,
        isBettingAllowed: true,
        isEnded: false,
        winner: -1,
        timestamp: serverTimestamp(),
      };

      await setDoc(doc(db2, "games", gamesLength.toString()), data);
      try {
        console.log("I was called");
        const gameId = parseInt(gamesLength);
        const response = await axios.post(
          "https://blockbetbot.onrender.com/api/start-game",
          {
            gameId,
          }
        );
        console.log(response.data);
        toast.success("Game Created Successfully");
        return response.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEndGame = async () => {
    try {
      const gameId = parseInt(formData2.gameId);
      const winner = parseInt(formData2.winner);
      const response = await axios.post(
        "https://blockbetbot.onrender.com/api/end-game",
        {
          gameId,
          winner,
        }
      );
      console.log(response.data);
      toast.success("Game Ended Successfully");
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  console.log(address);

  return address !== owner2 ? (
    <h1 className="text-red-500">You are not an Admin</h1>
  ) : (
    <div className="w-screen h-screen p-10 flex flex-col justify-start items-center gap-10">
      <h1 className="text-4xl font-bold">Admin Panel</h1>
      <div className="bg-red-500 w-[70%] flex flex-col justify-start items-start gap-4 overflow-scroll">
        <span
          className="text-2xl font-bold"
          onClick={() => {
            console.log(options, betAmounts);
          }}
        >
          Create Game
        </span>
        <div className=" w-full flex flex-row justify-start items-center gap-4">
          <span>Game Name</span>
          <input
            type="text"
            placeholder="Game Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="p-2 w-[30%] border-solid border-black border-2"
          ></input>
        </div>
        <div className=" w-full flex flex-row justify-start items-center gap-4">
          <span>Game Description</span>
          <input
            type="text"
            placeholder="Game Description"
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            className="p-2 w-[30%] border-solid border-black border-2"
          ></input>
        </div>
        <div className=" w-full flex flex-row justify-start items-center gap-4">
          <span>Max Options</span>
          <input
            type="number"
            placeholder="Max Options"
            value={formData.maxOptions}
            onChange={(e) => {
              handleMaxOptionsChange(e);
            }}
            className="p-2 w-[30%] border-solid border-black border-2"
          ></input>
        </div>
        <div className=" w-full flex flex-col justify-start items-start gap-2">
          {options.map((option, index) => {
            return (
              <div
                key={index}
                className="w-full flex flex-row justify-start items-center gap-4"
              >
                <span>Option {index}:</span>
                <input
                  type="text"
                  placeholder={`Option ${index + 1}`}
                  value={option}
                  onChange={(e) => {
                    const newOptions = [...options];
                    newOptions[index] = e.target.value;
                    setOptions(newOptions);
                  }}
                  className="p-2 w-[30%] border-solid border-black border-2"
                ></input>
              </div>
            );
          })}
        </div>
        <button
          className="bg-blue-400 rounded-lg w-[10rem] h-[3rem]"
          onClick={handleCreateGame}
        >
          Create Game
        </button>
        <span
          className="text-2xl font-bold"
          onClick={() => {
            console.log(options, betAmounts);
          }}
        >
          End Game
        </span>
        <span>Current Game ID: {gamesLength - 1}</span>
        <div className="w-full flex flex-row justify-start items-center gap-4">
          <div className="w-[30%] flex flex-row justify-start items-center gap-2">
            <span className="w-[50%]">Game Id:</span>
            <input
              type="number"
              placeholder="Game Id"
              value={formData2.gameId}
              onChange={(e) => {
                setFormData2({ ...formData2, gameId: e.target.value });
              }}
              className="p-2 w-full border-solid border-black border-2"
            ></input>
          </div>
          <div className="w-[30%] flex flex-row justify-start items-center gap-2">
            <span>Winner:</span>
            <input
              type="number"
              placeholder="Winner"
              value={formData2.winner}
              onChange={(e) => {
                setFormData2({ ...formData2, winner: e.target.value });
              }}
              className="p-2 w-full border-solid border-black border-2"
            ></input>
          </div>
          <div className="w-[5rem]"></div>
          <button
            className="bg-blue-400 rounded-lg w-[10rem] h-[3rem]"
            onClick={handleEndGame}
          >
            End Game
          </button>
        </div>

        {/* name: "IndvsWi"
        description: "India vs West Indies , 8:00 PM (IST), 15th Aug 2023"
        betAmounts: [0, 0]
        gameId: length+1
        isBettingAllowed: true
        isEnded: false
        maxOptions: 2
        options: ["India", "West Indies"]
        timestamp: August 14, 2023 at 5:30:16 PM UTC+5:30
        totalAmount: 0
        winner: 2 */}
      </div>
    </div>
  );
};

export default AdminPanel;

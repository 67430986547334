import React from "react";
import { IoFileTrayFull } from "react-icons/io5";
const LeaderboardData = [
  {
    user: "0x0d5E...c533",
    wagered: "8888888",
    earnings: "8888888",
    bets: "8888888",
    roi: "8888888",
  },
];
const Leaderboard = () => {
  return (
    <div className=" sm:text-lg w-full ">
      {/* <div class=" overflow-x-auto  md:overflow-x-hidden bg-[#181D2A] sm:p-[2%] p-[3%] mt-[3%] rounded-xl flex gap-0 sm:gap-4 flex-col">
        <div className="flex   space-x-4 ">
          <button class=" bg-[#45F882] rounded-xl font-semibold text-black  py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            USER
          </button>
          <button class=" bg-[#45F882] rounded-xl font-semibold text-black  py-2 px-4 mb-4 min-w-[200px] md:mb-0">
            TOTAL WAGERED
          </button>
          <button class=" bg-[#45F882] rounded-xl font-semibold text-black  py-2 px-4 mb-4 min-w-[200px] md:mb-0">
            TOTAL EARNINGS
          </button>
          <button class=" bg-[#45F882] rounded-xl font-semibold text-black  py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            TOTAL BETS
          </button>
          <button class="bg-[#45F882] rounded-xl font-semibold text-black  py-2 px-4 mb-4 min-w-[150px] md:mb-0">
            ROI
          </button>
        </div>

        <div className="flex justify-center items-center w-full flex-col my-[2%]">
          <div className="text-[#45F882] text-[50px]">
            <IoFileTrayFull />
          </div>

          <h4>No data to show </h4>
        </div>

        {LeaderboardData.map((item, index) => {
        return (
          <div key={index} className="flex items-center py-1 font-[400] text-[14px] border-b-[2px] border-white ">
            <p className="min-w-[190px]  pl-2">{item.user}</p>
            <p className="min-w-[190px] ">{item.wagered}</p>
            <p className="min-w-[190px] ">{item.earnings}</p>
            <p className="min-w-[155px] ">{item.bets}</p>
            <p className="min-w-[190px] pl-4 ">{item.roi}</p>
          </div>
        );
      })} 
      </div> */}
      <h6 className="text-center text-[#45F882] mt-[3%] font-medium">COMING SOON </h6>
    </div>
  );
};

export default Leaderboard;

import { useEffect } from "react";
import { toast } from "react-toastify";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "./../config/firebase"; // Ensure you have your Firestore instance configured

const RaceComponent = ({ currentRaceid, address }) => {

  useEffect(() => {
    if (currentRaceid) {
      const raceRef = doc(db, "games", currentRaceid.toString());
      const unsubscribe = onSnapshot(raceRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.isEnded) {
            const winnerID = data.winner;
            if (address) {
              const isWinner = address === winnerID;
              toast.info(
                isWinner
                  ? "Congratulations! You won the bet!"
                  : "You lost the bet."
              );
            }
          }
        }
      });
      return () => unsubscribe();
    }
  }, [currentRaceid, address]);

  return (
<></>
  );
};

export default RaceComponent;
